import { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [basicAmount, setBasicAmount] = useState(undefined);
  const [date, setDate] = useState(undefined);

  useEffect(() => {
    fetch("https://g.nav.no/api/v1/grunnbel%C3%B8p")
      .then((res) => res.json())
      .then((res) => {
        setBasicAmount(res["grunnbeløp"]);
        setDate(new Date(res.dato));
      })
  }, []);

  const locales = ["nb-NO", "no", "sv", "dk", "en"];

  return (
    <div className="App">
      <header className="App-header">
        {(basicAmount !== undefined && <div>Laster ...</div>) || (
          <div>
            Grunnbeløpet (G) per{" "}
            {new Intl.DateTimeFormat(locales, {
              dateStyle: "long",
            }).format(date)}{" "}
            er kr {basicAmount.toLocaleString(locales)}.
          </div>
        )}
        <p>
          Grunnbeløpet brukes til å beregne mange av NAVs utbetalinger.
          <br />
          Grunnbeløpet justeres 1. mai hvert år og blir fastsatt etter
          trygdeoppgjøret.
        </p>
      </header>
    </div>
  );
}

export default App;
